<template>
  <!-- begin:: Header -->
  <div id="kt_header" ref="kt_header" class="header" v-bind:class="headerClasses">
    <div class="container-fluid d-flex align-items-center justify-content-between">
      <div class="float-left">
        <div class="float-left">
          <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-3">
            Periode Transaksi :
          </span>
          <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {{ dateFormatId(user.periode_awal) }}
          </span>

          <span class="text-muted font-weight-bolder font-size-base d-none d-md-inline mr-3">
            s.d
          </span>

          <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-10">
            {{ dateFormatId(user.periode_akhir) }}
          </span>
        </div>
      </div>
      <KTTopbar></KTTopbar>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import {
  mapGetters
} from "vuex";
import KTTopbar from "@/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import helper from "@/core/helper";

export default {
  name: "KTHeader",
  components: {
    KTTopbar,
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "user"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  },
  methods: {
    ...helper,
  }
};
</script>
